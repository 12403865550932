<template>
  <div id="admin-editadmin">
    <div class="title">
      Adminler <span v-if="admins.Items.length > 0">({{ admins.Items.length }})</span>
    </div>

    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }"><i class="icon-home"></i></el-breadcrumb-item>
        <el-breadcrumb-item>Anasayfa</el-breadcrumb-item>
        <el-breadcrumb-item>Adminler</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="buttons-group">
        <div class="search-bar">
          <div class="search">
            <el-input placeholder="Arama..." v-model="filter.SearchTerm" clearable>
              <i slot="prefix" class="el-input__icon el-icon-search" style="color: #8697a8; font-weight: bold"></i>
            </el-input>
          </div>
        </div>
        <el-button class="add" @click="addAdminDialog = true"><i class="icon-plus"></i></el-button>
      </div>
    </div>

    <div class="all-data">
      <div class="doc-grid-system">
        <div class="mb"></div>
        <div class="all-cards">
          <div class="card" v-for="item in admins.Items" :key="item.ID">
            <el-button class="more-info">
              <el-dropdown>
                <span class="el-dropdown-link actions-menu"> <i class="icon-dots"></i> </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item>
                    <span @click="beginUpdate(item)" style="display: block; width: 100%">Düzenle</span>
                  </el-dropdown-item>
                  <el-dropdown-item divided v-if="item.ActiveStatus != 2">
                    <span style="display: block" @click="userStatusChange(item.ID)">Pasif Yap</span>
                  </el-dropdown-item>
                  <el-dropdown-item divided v-if="item.ActiveStatus == 2">
                    <span style="display: block" @click="userStatusChange(item.ID)">Aktif Yap</span>
                  </el-dropdown-item>
                  <el-dropdown-item divided><span class="tex-danger" style="display: block; width: 100%" @click="deleteAdmin(item)">Sil</span></el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </el-button>
            <div class="img">
              <template v-if="item.Image">
                <img :src="item.Image" alt="" />
              </template>
              <template v-else>
                <img src="../../assets/img/no-admin.png" alt="" />
              </template>
            </div>
            <div class="empty"></div>

            <div class="top">
              <div class="name">{{ item.FirstName + " " + item.LastName }}</div>
              <div class="color-dot">
                <div class="dot" :class="{ green: item.ActiveStatus == 1, red: item.ActiveStatus == 2 }"></div>
                {{ getEnumsDisplay("ActiveStatus", item.ActiveStatus) }}
              </div>
            </div>
            <div class="adress-info">
              <div class="city">
                <div class="text-left"><i class="icon-location"></i> Şehir</div>
                <div class="text-right" v-if="item.LivingAddress.District || item.LivingAddress.City">{{ item.LivingAddress.District }} <span v-if="item.LivingAddress.District && item.LivingAddress.City">/</span> {{ item.LivingAddress.City }}</div>
                <div class="text-right" v-else>Bilgi yok</div>
              </div>
              <div class="email">
                <div class="text-left"><i class="icon-message"></i> E-posta</div>
                <div class="text-right">{{ item.Email }}</div>
              </div>
              <div class="phone-number">
                <div class="text-left"><i class="icon-mobile"></i> Telefon</div>
                <div class="text-right">{{ item.PhoneNumber }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <el-dialog :visible.sync="addAdminDialog" custom-class="add-admin-dialog" :with-header="false" top="1vh" :close-on-click-modal="false" :modal-append-to-body="false" :show-close="false">
        <div class="close" @click="addAdminDialog = false">
          <i class="icon-close"></i>
        </div>
        <el-form ref="form" :model="form">
          <div class="head-tag new-doc">Admin Ekle/Güncelle</div>
          <div class="all-parts">
            <div class="mid-tag">Kişisel Bilgiler</div>
            <div class="part">
              <input type="file" @change="uploadFile" style="display: none" ref="file" />
              <div class="image" @click="uploadFiles()" :style="{ backgroundImage: 'url(' + form.Image + ')' }">
                <i class="icon-camera-2"></i>
                <div>Fotoğraf Yükle</div>
              </div>
            </div>
            <div class="part">
              <div class="text">Admin Adı <span class="">*</span></div>
              <div class="input"><el-input v-model="form.FirstName"></el-input></div>
              <span class="error">{{ $errorMessage("FirstName", ValidationErrors) }}</span>
            </div>
            <!-- Admin Rolu seçme 10 11 olarak seçicek ve Role diye forma eklemek lazım -->
            <div class="part">
              <div class="text">Admin Rolü <span class="">*</span></div>
              <div class="input">
                <el-select v-model="form.Role" placeholder="Seçiniz...">
                  <el-option label="Admin" :value="10"></el-option>
                  <el-option label="Moderator" :value="11"></el-option>
                </el-select>
              </div>
              <span class="error">{{ $errorMessage("Role", ValidationErrors) }}</span>
            </div>
            <div class="part">
              <div class="text">Soyadı <span class="">*</span></div>
              <div class="input"><el-input v-model="form.LastName"></el-input></div>
              <span class="error">{{ $errorMessage("LastName", ValidationErrors) }}</span>
            </div>
            <div class="part">
              <div class="text">E-Posta <span class="">*</span></div>
              <div class="input"><el-input v-model="form.Email"></el-input></div>
              <span class="error">{{ $errorMessage("Email", ValidationErrors) }}</span>
            </div>
            <div class="part">
              <div class="text">Cep Telefonu <span class="">*</span></div>
              <div class="input-container">
                <div class="phone">
                  <vue-phone-number-input
                    v-model="form.PhoneNumber"
                    @update="phoneFormatter = $event"
                    :no-example="true"
                    ref="phoneInput2"
                    :translations="{
                      countrySelectorLabel: $localization('web-login-country-code'),
                      countrySelectorError: $localization('web-login-select-country-code'),
                      phoneNumberLabel: $localization('web-login-phone-number'),
                      example: $localization('web-login-example'),
                    }"
                    size="lg"
                    default-country-code="TR" />
                </div>
                <span class="error">{{ $errorMessage("PhoneNumber", ValidationErrors) }}</span>
              </div>
            </div>
            <!-- <div class="part">
              <div class="text">Ünvan <span class="">*</span></div>
              <div class="input"><el-input v-model="form.Specialist"></el-input></div>
              <span class="error">{{ $errorMessage("Specialist", ValidationErrors) }}</span>
            </div> -->
            <div class="part">
              <div class="text">Cinsiyet</div>
              <div class="input">
                <el-select placeholder="Seçiniz..." v-model="form.Gender">
                  <el-option :label="item.Key" :value="item.Value" v-for="(item, index) in genders" :key="index"></el-option>
                </el-select>
              </div>
              <span class="error">{{ $errorMessage("Gender", ValidationErrors) }}</span>
            </div>
            <div class="part">
              <div class="text">Doğum Tarihi</div>
              <div class="input">
                <el-date-picker v-model="form.BirthDate" format="dd.MM.yyyy" type="date" placeholder="Seçiniz..." default-value="1980-01-01" prefix-icon="none"> </el-date-picker>
              </div>
              <span class="error">{{ $errorMessage("BirthDate", ValidationErrors) }}</span>
            </div>
            <!-- <div class="part">
              <div class="text">Hakkında</div>
              <div class="textarea"><el-input type="textarea" v-model="form.Biography"></el-input></div>
              <span class="error">{{ $errorMessage("Biography", ValidationErrors) }}</span>
            </div> -->

            <div class="mid-tag">Adres Bilgileri</div>
            <!-- if value is null, write red span in class or just "" -->
            <div class="part">
              <div class="text">Ülke: <span class="">*</span></div>
              <div class="input"><el-input v-model="form.Country"></el-input></div>
              <span class="error">{{ $errorMessage("Country", ValidationErrors) }}</span>
            </div>
            <div class="part">
              <div class="text">İl: <span class="">*</span></div>
              <div class="input"><el-input v-model="form.City"></el-input></div>
              <span class="error">{{ $errorMessage("City", ValidationErrors) }}</span>
            </div>
            <div class="part">
              <div class="text">İlçe: <span class="">*</span></div>
              <div class="input"><el-input v-model="form.District"></el-input></div>
              <span class="error">{{ $errorMessage("District", ValidationErrors) }}</span>
            </div>
            <div class="part">
              <div class="text">Açık Adres: <span class="">*</span></div>
              <div class="input"><el-input v-model="form.Address"></el-input></div>
              <span class="error">{{ $errorMessage("Address", ValidationErrors) }}</span>
            </div>
            <!-- <div class="part">
              <div class="text">Posta Kodu: <span class="">*</span></div>
              <div class="input"><el-input v-model="form.ZipCode"></el-input></div>
              <span class="error">{{ $errorMessage("ZipCode", ValidationErrors) }}</span>
            </div>
            backende eklenecek zip code -->

            <div class="mid-tag">Dokümanlar ({{ form.Attachments.length }})</div>

            <div class="upload-folder">
              <div class="uploading-all-documents part">
                <ul class="file-list">
                  <li class="list-item" v-for="(item, index) in form.Attachments" :key="index">
                    <div class="file">
                      <img :src="getExtension(item.Extension)" />
                    </div>
                    <div class="details">
                      <div class="title">{{ item.FilenameReal }}</div>
                      <div class="byte">{{ fileSize(item.FileSizeInMb) }}</div>
                    </div>
                    <div class="icon-delete" @click="clearFiles(index)"></div>
                  </li>
                </ul>
                <el-upload
                  class="el-up"
                  :action="$client.defaults.baseURL + 'Account/UploadFile?&type=4'"
                  :headers="{
                    Authorization: $client.defaults.headers.common['Authorization'],
                  }"
                  :show-file-list="false"
                  multiple
                  :on-success="fileSuccess">
                  <el-button size="small" style="justify-self: flex-start" type="primary">
                    <i class="icon-plus"></i>
                    Dosya Ekle</el-button
                  >
                </el-upload>
              </div>
            </div>
          </div>
          <div class="button-group-admin">
            <el-button class="cancelReportAdmin" @click="addAdminDialog = false">İptal</el-button>
            <el-button class="confirmReportAdmin" type="primary" @click="save()" :disabled="onSave" :loading="onSave"><i class="icon-send"></i>Kaydet</el-button>
          </div>
        </el-form>
      </el-dialog>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      phoneFormatter: null,
      countryCode: null,
      token: localStorage.getItem("token"),
      addAdminDialog: false,
      onSave: false,
      ValidationErrors: [],
      genders: [
        {
          Key: "Kadın",
          Value: 1,
        },
        {
          Key: "Erkek",
          Value: 2,
        },
      ],
      form: {
        Id: null,
        FirstName: "",
        LastName: "",
        Email: "",
        PhoneNumber: "",
        Gender: null,
        BirthDate: "",
        Country: "",
        City: "",
        Role: 10,
        District: "",
        Address: "",
        ZipCode: "",
        Attachments: [],
        Image: null,
      },
      admins: {
        Items: [],
        TotalCount: 0,
        PageCount: 0,
      },
      filter: {
        Skip: 0,
        Take: 100,
        Roles:[10,11],
        SearchTerm: null,
      },

      searchTimeout: null,
    };
  },
  async beforeMount() {
    await this.getData();
  },
  methods: {
    async userStatusChange(id) {
      var payload = {
        Id: id,
      };

      this.$confirm("Bu hesabı aktif/pasif yapmak istediğinize emin misiniz?", "Uyarı", {
        confirmButtonText: "Evet",
        cancelButtonText: "Hayır",
        customClass: "alert-dialog",
        confirmButtonClass: "primary-btn btn-shadow",
        cancelButtonClass: "secondarary-btn",
        type: "warning",
      })
        .then(async () => {
          var res = await this.$client.post("/User/ChangeStatus", payload);
          if (res.HasError) {
            this.$message.error(res.data.Message);
          } else {
            this.$message.success(res.data.Message);
          }
          await this.getData();
        })
        .catch(() => {});
    },
    async uploadFile() {
      let file = this.$refs.file.files[0];
      var fd = new FormData();
      fd.append("file", file);
      var res = await this.$client.post("/Account/UploadFile?type=3", fd, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (res.data.HasError != undefined && res.data.HasError == true) this.$message.error(res.data.Message);
      else this.form.Image = res.data.Item.Url;

      this.$refs.file.value = null;
    },
    uploadFiles() {
      this.$refs.file.click();
    },
    clearFiles(index) {
      this.form.Attachments.splice(index, 1);
    },
    fileSuccess(res) {
      this.form.Attachments.push(res.Item);
    },
    beginUpdate(item) {
      if (item.LivingAddress != null) {
        item.Country = item.LivingAddress.Country;
        item.City = item.LivingAddress.City;
        item.District = item.LivingAddress.District;
        item.Address = item.LivingAddress.Address;
        item.ZipCode = item.LivingAddress.ZipCode;
      }

      this.form = JSON.parse(JSON.stringify(item)); //kıdemli
      this.form.Id = item.ID;

      this.ValidationErrors = [];
      this.addAdminDialog = true;
    },
    clearForm() {
      this.ValidationErrors = [];
      this.form = {
        Id: null,
        FirstName: "",
        LastName: "",
        Email: "",
        PhoneNumber: "",
        Gender: null,
        BirthDate: "",
        Country: "",
        City: "",
        District: "",
        Address: "",
        ZipCode: "",
        Attachments: [],
      };
    },
    async deleteAdmin(item) {
      this.$confirm("Bu hesabı silmek istediğinize emin misiniz?", "Uyarı", {
        confirmButtonText: "Evet",
        cancelButtonText: "Hayır",
        customClass: "alert-dialog",
        confirmButtonClass: "primary-btn btn-shadow",
        cancelButtonClass: "secondarary-btn",
        type: "warning",
      })
        .then(async () => {
          var res = await this.$client.post("/Admin/DeleteAdmin", { Id: item.ID });
          if (res.data.HasError) {
            this.$message.error(res.data.Message);
          } else {
            this.$message.success("Admin başarıyla silindi.");
            this.getData();
          }
        })
        .catch(() => {});
    },
    async getData() {
      var res = await this.$client.post("/User/List", this.filter);
      if (res.data.HasError) {
        this.$message.error(res.data.Message);
      } else {
        this.admins = res.data.Data;
      }
    },
    async save() {
      this.ValidationErrors = [];

      this.onSave = true;

      if (this.phoneFormatter != null) {
        if (this.phoneFormatter.isValid != false) {
          this.form.PhoneNumber = this.phoneFormatter.formatInternational.replaceAll(" ", "");
        } else {
          this.onSave = false;
        }
      } else {
        this.$message.warning("Lütfen doğru telefon numarası giriniz.");
        this.onSave = false;

        return;
      }

      if (this.form.BirthDate == "") {
        this.form.BirthDate = null;
      }
      var res = await this.$client.post("/Admin/AddUpdateAdmin", this.form);
      this.ValidationErrors = res.data.ValidationErrors;
      if (res.data.HasError) {
        this.$message.error(res.data.Message);
      } else {
        this.addAdminDialog = false;
        this.clearForm();
        this.$message.success(res.data.Message);
        await this.getData();
      }
      this.onSave = false;
    },
  },
  watch: {
    "filter.SearchTerm": function () {
      window.clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this.getData();
      }, 400);
    },
    addAdminDialog: function () {
      if (this.addAdminDialog == false) {
        this.clearForm();
      }
    },
  },
};
</script>

<style lang="less">
#admin-editadmin {
  padding: 42px 30px;

  .title {
    font-size: 30px;
    color: #44566c;
    font-weight: 700;
    line-height: 35px;
    padding-bottom: 22px;

    span {
      color: #8697a8;
    }
  }

  .breadcrumb {
    .buttons-group {
      display: flex;
      gap: 24px;
      align-items: center;

      button {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        width: 40px;
        border: none;
        border-radius: 50%;
      }

      .delete {
        background: #eaedf0;

        i {
          background: #44566c;
        }
      }

      .add {
        box-shadow: 0px 8px 16px rgba(0, 129, 255, 0.2);
        background: #234a98;

        i {
          background: white;
        }
      }

      .search-bar {
        .search {
          .el-input {
            width: 290px;
            height: 40px;

            input {
              padding-left: 40px;
              filter: drop-shadow(0px 4px 4px rgba(169, 194, 209, 0.05)) drop-shadow(0px 8px 16px rgba(169, 194, 209, 0.1));
              border: none;
              border-radius: 5px;
            }

            ::placeholder {
              color: #8697a8;
              font-size: 15px;
              font-weight: 400;
            }

            i {
              color: #8697a8;
              font-size: 15px;
              background-color: rgba(255, 255, 255, 0);
              padding: 0 10px;
            }
          }
        }
      }
    }
  }

  .color-dot {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;

    .dot {
      height: 5px;
      width: 5px;
      background-color: black; // default #ff3d57 danger #FDBF5E kritik #09B66D neew
      border-radius: 50%;
      display: inline-block;

      &.red {
        background-color: #ff3d57;
      }

      &.green {
        background-color: #09b66d;
      }

      &.yellow {
        background-color: #fdbf5e;
      }
    }
  }

  .all-data {
    .list-table {
      .el-table {
        background-color: rgba(255, 255, 255, 0) !important;
        box-shadow: none;

        &::before {
          height: 0;
        }
      }

      .el-table tr {
        background-color: rgba(255, 255, 255, 0);
      }

      table {
        border-spacing: 0px 12px !important;
        border-collapse: separate;
      }

      table td {
        border: none;
      }

      .el-table .el-table__body-wrapper table tr td:last-child {
        color: #b3c0ce;
      }

      .el-table__header {
        border-spacing: 0px 0px !important;
        border-collapse: separate;
      }

      thead {
        th {
          border: none !important;
          background-color: rgba(255, 255, 255, 0) !important;
          padding-bottom: 0;
          padding-top: 0;
        }

        .cell {
          font-weight: 500;
        }
      }

      tbody {
        height: auto;

        .el-table__row {
          filter: drop-shadow(0px 2px 4px rgba(169, 194, 209, 0.05)) drop-shadow(0px 12px 16px rgba(169, 194, 209, 0.1));

          &:hover {
            background-color: white !important;
          }

          td {
            height: 60px;
          }

          td:first-child {
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
          }

          td:last-child {
            border-bottom-right-radius: 10px;
            border-top-right-radius: 10px;
          }

          background-color: white !important;
          border-radius: 10px !important;
        }

        .el-button {
          border: none;
        }

        .el-table-column--selection {
          .cell {
            justify-content: center !important;
          }
        }

        td {
          .cell {
            display: flex;
            align-items: center;
            gap: 10px;

            i {
              height: 40px;
            }
          }
        }

        .three-dots {
          background-color: inherit;

          i {
            height: 25px;
            width: 25px;
          }
        }

        .cell {
          .name-all {
            display: flex;
            gap: 10px;
            align-items: center;

            .name-short {
              display: flex;
              align-items: center;
              justify-content: center;
              min-width: 40px;
              min-height: 40px;
              border: none;
              border-radius: 50%;
              font-size: 14px;
              font-weight: 500;
            }

            .name-short.first {
              background-color: #ff3d57;
              color: white;
            }

            .name-short.second {
              background-color: #fdbf5e;
              color: white;
            }

            .name-short.third {
              background-color: #22cce2;
              color: white;
            }

            .name-short.fourth {
              background-color: #ff8a48;
              color: white;
            }

            .name-container {
              .name {
                word-break: break-word;
                color: #44566c;
                font-weight: 400;
                font-size: 15px;
              }

              .mail {
                color: #8697a8;
                font-weight: 400;
                font-size: 13px;
              }
            }
          }
        }

        .color-dot {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 4px;

          .dot {
            height: 5px;
            width: 5px;
            background-color: #ff3d57; // default danger #FDBF5E kritik #09B66D neew
            border-radius: 50%;
            display: inline-block;
          }
        }

        .pagination {
          margin-top: 20px;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .info {
            font-size: 15px;
            font-weight: 400;
            color: #8697a8;
          }

          .el-pagination {
            padding: 0;
            display: flex;
            flex-direction: row;
            align-items: center;

            box-shadow: 4px 4px 24px rgba(0, 0, 0, 0.1);
            background-color: white;
            border-radius: 8px;

            button {
              background-color: white;
              padding: 0;
              margin: 0;
              height: 100% !important;

              &.btn-prev {
                border-radius: 8px 0 0 8px;
                border-right: 1px solid #eaedf0;
              }

              &.btn-next {
                border-radius: 0 8px 8px 0;

                border-left: 1px solid #eaedf0;
              }
            }

            .el-pager {
              margin: 0 10px;
              padding: 5px;
              display: flex;
              flex-direction: row;
              gap: 5px;
              justify-content: space-between;

              li {
                padding: 0;
                margin: 0;
                background-color: white;

                &.active {
                  background-color: #234a98;
                  border-radius: 8px;
                  box-shadow: 4px 4px 24px rgba(0, 0, 0, 0.05);
                }
              }
            }
          }
        }
      }
    }

    .doc-grid-system {
      .mb {
        margin-bottom: 120px;
      }

      .all-cards {
        display: grid;
        grid-template-columns: auto auto auto;

        @media screen and (min-width: 1921px) {
          grid-template-columns: auto auto auto auto;
        }

        gap: 108px 28px;
        flex-wrap: wrap;

        .card {
          height: 370px;
          width: 100%;
          filter: drop-shadow(0px 2px 4px rgba(169, 194, 209, 0.05)) drop-shadow(0px 12px 16px rgba(169, 194, 209, 0.1));
          border-radius: 20px;
          background-color: white;
          position: relative;

          .img {
            position: absolute;
            height: 170px;
            width: 170px;
            margin-left: auto;
            margin-right: auto;
            top: -86px;
            left: calc(50% - 85px);
            z-index: 4;

            img {
              display: block;
              border-radius: 40px;
              width: 100%;
              height: 100%;
              object-fit: cover;
              box-shadow: 0px 2px 4px rgba(169, 194, 209, 0.05), 0px 12px 16px rgba(169, 194, 209, 0.1);
            }
          }

          .more-info {
            position: absolute;
            top: 0;
            right: 0;
            border: none;
            background-color: rgba(255, 255, 255, 0);

            &:hover {
              background-color: rgba(255, 255, 255, 0);
            }
          }

          .empty {
            height: 126px;
          }

          .top {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            gap: 9px;
            height: 90px;
            background-color: white;
            border-bottom: 1px solid #eaedf0;

            .name {
              font-weight: 500;
              font-size: 30px;
              line-height: 35px;
              color: #44566c;
            }

            .title {
              display: flex;
              align-items: center;
              font-style: normal;
              font-weight: 400;
              font-size: 13px;
              background-color: #0081ff;
              color: #ffffff;
              padding: 2px 5px;
              border-radius: 5px;
            }
          }

          .name-info {
            height: 90px;
            background-color: white;
            border-bottom: 1px solid #eaedf0;
          }

          .adress-info {
            height: 156px;
            background-color: #f8fafb;
            display: flex;
            flex-direction: column;
            padding: 25px 30px 25px 30px;
            gap: 25px;
            border-bottom-left-radius: 20px;
            border-bottom-right-radius: 20px;

            .city,
            .email,
            .phone-number {
              display: flex;
              justify-content: space-between;
              align-items: center;
            }

            .text-left {
              display: flex;
              gap: 8px;
              font-weight: 400;
              font-size: 15px;
              line-height: 18px;
              color: #44566c;
              align-items: center;
            }

            .text-right {
              font-weight: 400;
              font-size: 15px;
              line-height: 18px;
              color: #8697a8;
            }
          }
        }
      }
    }
  }
}

.add-admin-dialog {
  width: 50%;
  border-radius: 14px !important;

  .el-dialog__header {
    padding: 0;
  }

  .close {
    position: absolute;
    right: 1rem;
    cursor: pointer !important;
    top: 1rem;
    width: 28px;
    height: 28px;
    padding: 3px;
    border-radius: 50%;
    background-color: #eaedf0;

    .icon-close {
      width: 100%;
      height: 100%;
    }
  }

  .el-dialog__body {
    padding: 0;
  }

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-thumb {
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: white;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #f1f1f1;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #8697a8;
  }

  .el-form {
    padding: 20px;

    .all-parts {
      margin-top: 25px;
      display: flex;
      flex-direction: column;
      gap: 30px;
      max-height: 80vh;
      overflow-y: scroll;
      padding-right: 30px;
    }

    .head-tag {
      font-weight: 700;
      font-size: 30px;
      line-height: 35px;
      color: #44566c;
    }

    .mid-tag {
      font-weight: 600;
      font-size: 20px;
      line-height: 25px;
      color: #44566c;
    }

    .el-select,
    .el-input {
      width: 100%;
      height: 38px;
    }

    .part {
      & > .input-container {
        margin-bottom: 20px;
        display: flex;
        flex-direction: column;
        gap: 5px;

        .phone {
          padding: 2px;

          .vue-phone-number-input {
            width: 100%;
            display: flex;
            gap: 10px;
          }

          .flex-1 {
            width: 100%;
          }

          display: flex;
          flex-direction: row;
          gap: 10px;

          .vue-country-select {
            border: none;
            background-color: #f8fafb;
            border-radius: 4px;

            .dropdown.open {
              .dropdown-list {
                border: none;

                .dropdown-item {
                  height: 40px;
                  display: flex;
                  align-items: center;
                  gap: 10px;

                  &.highlighted {
                    background-color: #f8fafb;
                  }

                  &.last-preferred {
                    border-bottom: 1px solid #234a98;
                  }
                }

                &::-webkit-scrollbar {
                  width: 5px;
                }

                &::-webkit-scrollbar-thumb {
                  border: 4px solid rgba(0, 0, 0, 0.2);
                  background-clip: padding-box;
                  border-radius: 4px;
                }

                /* Track */
                &::-webkit-scrollbar-track {
                  background: white;
                }
              }
            }
          }

          .phone-2 {
            position: relative;
            width: 100%;

            &::after {
              content: "";
              position: absolute;
              width: 22px;
              height: 22px;
              left: 13px;
              top: 9px;
              z-index: 2;
              background-color: #44566c;
              mask: url("/img/icons/icon-mobile.svg") no-repeat center;
              -webkit-mask: url("/img/icons/icon-mobile.svg") no-repeat center;
            }

            input {
              z-index: 1;
              border: none;
              background-color: #f8fafb;
              text-indent: 48px;
              padding: 0;
              font-size: 15px;
              font-weight: 400;
              line-height: 21px;
              width: 100%;
            }
          }
        }
      }

      .image {
        cursor: pointer;
        width: 170px;
        background-position: center;
        background-size: cover !important;
        height: 170px;
        background: #44566c;
        opacity: 0.6;
        border-radius: 40px;
        box-shadow: 0px 16px 32px rgba(169, 194, 209, 0.55), 0px 8px 16px rgba(169, 194, 209, 0.45);
        display: flex;
        flex-direction: column;
        justify-content: center;

        div,
        i {
          color: #fff;
          font-weight: 500;
          font-size: 15px;
          align-self: center;
        }
      }

      .text {
        font-size: 15px;
        font-weight: 400;
        line-height: 17px;
        color: #8697a8;
        margin-bottom: 10px;

        .red {
          color: #ff3d57;
        }
      }

      .el-input__inner {
        padding: 12px 19px;
      }

      input,
      textarea {
        background-color: #f8fafb;
        font-size: 15px;
        font-weight: 400;
        color: #44566c;
        border: none;

        &:focus {
          border-color: inherit;
        }
      }

      .el-textarea {
        textarea {
          height: 150px;
          padding: 16px 18px;
        }
      }
    }

    .button-group-admin {
      padding-top: 10px;
    }
  }

  .upload-folder {
    .part {
      margin-bottom: 35px;
    }

    .upload-area {
      display: flex;
      justify-content: center;

      .upload-demo {
        .el-upload {
          .el-upload-dragger {
            background-color: #eaedf0;
            width: 401px;
            height: 100px;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 5px;

            i {
              margin: 0;
              font-size: 30px;
              line-height: 0;
            }

            .el-upload__text {
              font-size: 15px;
              color: #44566c;
              font-weight: 400;

              em {
                color: #234a98;
              }
            }
          }
        }

        .el-upload-list {
          background-color: #f8fafb;
        }
      }
    }

    .uploading-all-documents {
      max-height: 257px;
      overflow: auto;
      display: flex;
      flex-direction: column;
      align-items: center;

      .document {
        width: 420px;
        height: 75px;
        background-color: #f8fafb;
        margin: 8px 0;
      }

      .still-uplaoading {
        display: flex;
        gap: 26px;
        padding: 18px 16px 16px 16px;

        .file-type {
          i {
            width: 31px;
            height: 40px;
          }
        }

        .right {
          width: 100%;

          .top {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .upload-info {
              color: #44566c;
              font-size: 13px;
              font-weight: 400;
            }

            .cancel-button {
              background-color: #44566c;
              height: 14px;
              width: 14px;
              border-radius: 50%;
              padding: 12px;
              display: inline-flex;
              align-items: center;
              justify-content: center;

              i {
                width: 8px;
                height: 8px;
                display: block;
                mask: url("../../assets/img/icons/Cross.svg") no-repeat center;
                -webkit-mask: url("../../assets/img/icons/Cross.svg") no-repeat center;
                background-color: white;
              }
            }
          }
        }
      }

      .uploaded {
        display: flex;
        gap: 26px;
        padding: 18px 16px 16px 16px;

        .file-type {
          i {
            width: 31px;
            height: 40px;
          }
        }

        .right {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;

          .texts {
            .document-name {
              color: #44566c;
              font-size: 13px;
              font-weight: 400;
            }

            .document-size {
              color: #8697a8;
              font-size: 13px;
              font-weight: 400;
              margin-top: 4px;
            }
          }

          .delete {
            button {
              border: none;
              background-color: inherit;
              padding: 0;

              i {
                font-size: 22px;
                color: #8697a8;
              }
            }
          }
        }
      }
    }
  }
}
</style>
